/******************************************************************************/
/* BUTTONS                                                                    */
/******************************************************************************/

.btn.span-outline {
  opacity: 1.0;
  color: inherit;
  cursor: initial;
}

.btn-outline-secondary {
  color: $hbm-color-text;
}

.hbm-form-buttons.hbm-form-buttons-top {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;

  [class^="mini-"] {
    display: none;
  }

  svg.svg-inline--fa {
    display: none;
  }
}
