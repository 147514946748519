/******************************************************************************/
/* ICONS                                                                      */
/******************************************************************************/

.theme-hbmlg {
  --fa-primary-opacity: 1.0;
  --fa-secondary-opacity: 1.0;
  --fa-primary-color: #efc4b9;
  --fa-secondary-color: #e16748;
}
