/******************************************************************************/
/* POSITIONABLE                                                               */
/******************************************************************************/

[data-positionables] {

  [data-positionable-handle] {
    display: none;
  }

  &.positioning {
    [data-positionable] {
      div.hbm-form-group,
      div.card-separator-header {
        &:not([data-positionable-visible]) {
          display: none;
        }
      }

      div.hbm-form-group[data-positionable-visible] {
        margin: 0;

        label {
          display: none;
        }
      }

      div.hbm-form-body {
        padding: 5px 100px 5px 5px;
      }

      [data-positionable-handle] {
        display: inline-block;
        cursor: move;
      }
    }
  }

}
