/******************************************************************************/
/* STEPS                                                                      */
/******************************************************************************/

ul.steps {
  li.step {
    .step-text {
      line-height: 1;
    }
  }
}

/******************************************************************************/
/* SECTIONS                                                                   */
/******************************************************************************/

ul.sections {
  li.step {
    .step-text {
      line-height: 1;
    }
  }
}
