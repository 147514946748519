/******************************************************************************/
/* MISC                                                                       */
/******************************************************************************/

body.ajax-secured {
  [data-ajax-secured] {
    cursor: default;
  }
  [data-ajax-secured="hidden"] {
    display: none;
  }
}

/******************************************************************************/

.tag-editor {
  border: 1px solid #CCCCCC;
  border-radius: .25rem;
  padding: 0.375rem .75rem;
}

.editor-container {
  z-index: 1;
  border: 1px solid $input-border-color;
  border-radius: $input-border-radius;
}

/******************************************************************************/

#uploader {
  border: 5px dashed #CCC;
  border-radius: 10px;
  min-height: 250px;
}

/******************************************************************************/

#hbmlg-aside {

  #hbmlg-aside-drop {
    display: none;
    position: absolute;
    top: 10px;
    left: 10px;
    bottom: 10px;
    right: 10px;

    background-color: #f8f9fa;
    border: 5px dashed $hbm-color-highlight;
    border-radius: 10px;
  }

  &.droppable {
    #hbmlg-aside-drop {
      display: block;
    }
  }

}

.line-height-1 {
  line-height: 1;
}
.line-height-1-2 {
  line-height: 1.2;
}

.text-decoration-line-through {
  text-decoration: line-through;
}

.cursor-pointer {
  cursor: pointer;
}

/******************************************************************************/

.environment-dev {
  border-left:10px solid #dc3545;
}

.environment-stage {
  border-left:10px solid #FFC107;
}

.environment-prod {
}
