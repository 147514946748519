/******************************************************************************/
/* FORM                                                                       */
/******************************************************************************/

.hbm-form-controls {
  .hbm-form-help {
    display: block;
    margin-top: ($spacer * .25);
  }

  .hbm-form-input-groups.btn-group {
    flex-wrap: wrap;

    > .btn {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 0;
    }

    > .btn-group-header {
      width: 100%;
    }

    > .btn-group-separator {
      width: 100%;
    }
  }
}

[data-json-table] {
  th, td {
    border-color: $input-group-addon-border-color;
  }

  thead {
    background-color: $input-group-addon-bg;
    tr {
      th {
        &.cell-sort-handle {
          width: 10px;
        }
        &.cell-custom {}
        &.cell-btn-clone {
          width: 10px;
        }
        &.cell-btn-delete {
          width: 10px;
        }
      }
    }
  }

  tbody {
    tr {
      &.row-dummy {
        height: 0;
        td {
          padding: 0;
          border: none;
        }
      }
      &[data-json-table-row-template] {
        display: none;
      }
      td {
        &.cell-sort-handle {
          text-align: center;
          vertical-align: middle;
        }
        &.cell-custom {}
        &.cell-btn-clone {
          text-align: center;
          vertical-align: middle;
        }
        &.cell-btn-delete {
          text-align: center;
          vertical-align: middle;
        }
      }
    }
  }

  tfoot {
    background-color: transparent;
    //border: none;
    tr {
      td {
        &.cell-btn-add {
          border: none;
          text-align: center;
          vertical-align: middle;
        }
      }
    }
  }
}

/******************************************************************************/

.visible-upload,
.visible-choice,
.visible-extended {
  display: none;
}

[data-campaign-field-type="upload-image"],
[data-campaign-field-type="upload-video"] {
  .visible-upload:not(.visible-extended) {
    display: block;
  }
}

[data-campaign-field-type="choice"] {
  .visible-choice:not(.visible-extended) {
    display: block;
  }
}

.extended {
  .visible-extended:not(.visible-upload, .visible-choice) {
    display: block;
  }

  [data-campaign-field-type="upload-image"],
  [data-campaign-field-type="upload-video"] {
    .visible-upload.visible-extended {
      display: block;
    }
  }

  [data-campaign-field-type="choice"] {
    .visible-choice.visible-extended {
      display: block;
    }
  }
}
