/******************************************************************************/
/* TOOLTIPS                                                                   */
/******************************************************************************/

$hbmlg-tooltip-placements: top, right, bottom, left;

@mixin tooltip-variant($background, $border, $color) {
  @each $placement in $hbmlg-tooltip-placements {
    &.bs-tooltip-#{$placement},
    &.bs-tooltip-auto[x-placement^="#{$placement}"] {
      .arrow::before {
        border-#{$placement}-color: $background;
      }
    }
  }

  .tooltip-inner {
    color: $color;
    @include gradient-bg($background);
    border-color: $border;

    .tooltip-link {
      color: darken($color, 10%);
    }
  }
}

@each $color, $value in $theme-colors {
  .tooltip.tooltip-#{$color} {
    @include tooltip-variant(theme-color-level($color, $alert-bg-level), theme-color-level($color, $alert-border-level), theme-color-level($color, $alert-color-level));
  }
}
